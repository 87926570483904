import React from 'react';
import Header from '../component/header/Header';
import UserProfile from '../component/userProfile/UserProfile';

const Profile = ({CartItem}) => {
  return (
      <>
          <Header CartItem={CartItem} />
          <UserProfile />
      </>
  )
}

export default Profile;