import React  from 'react';
import { useEffect, useState } from "react";
import ProductDescription from "./ProductDescription";
import "./SingleProduct.css";
import { useDispatch, useSelector } from "react-redux";
import { addProductToCart } from "../../reduxStore/cartSlice";

const ProductByeOrAddToCartDetails = ({data}) => {
    const dispatch = useDispatch();

    const handleAddToCart = (product) => {
        dispatch(addProductToCart(product));
        // navigate("/cart");
    };
    
    return (
        <>
            <div className="singleProductCard">
                <div className="d-flex">
                    <div className="p-ratings">
                        <i className="fa fa-star me-1" />
                        <i className="fa fa-star me-1" />
                        <i className="fa fa-star me-1" />
                        <i className="fa fa-star me-1" />
                    </div>
                    <span className="ms-1 fw-bold">5.0</span>
                </div>
                <div className="about">
                    <span className="fw-bold">
                        {data.itemName}
                    </span>
                    <h4 className="fw-bold">{"\u09F3"} {data.salePrice}</h4>
                </div>
                <div className="buttons">
                    <button onClick={() => handleAddToCart(data)} className="btn btn-outline btn-long cart me-2">
                        Add to Cart
                    </button>
                    {/* <button className="btn btn-warning btn-long buy">Buy it Now</button> */}
                    {/* <button className="btn btn-light wishlist">
                                    <i className="fa fa-heart" />
                                </button> */}
                </div>
                <hr />
                <div className="product-description">
                    <div>
                        <span className="font-weight-bold">Color:</span>
                        <span> blue</span>
                    </div>
                    <div className="my-color">
                        <label className="radio me-2">
                            <input type="radio" name="gender" defaultValue="MALE" defaultChecked="" />
                            <span className="red" />
                        </label>
                        <label className="radio me-2">
                            <input type="radio" name="gender" defaultValue="FEMALE" />
                            <span className="blue" />
                        </label>
                        <label className="radio me-2">
                            <input type="radio" name="gender" defaultValue="FEMALE" />
                            <span className="green" />
                        </label>
                        <label className="radio me-2">
                            <input type="radio" name="gender" defaultValue="FEMALE" />
                            <span className="orange" />
                        </label>
                    </div>
                    {/* Product description */}
                    <ProductDescription data = {data} />
                </div>
            </div>
        </>
    )
}

export default ProductByeOrAddToCartDetails;