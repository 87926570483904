import React from "react";
import NewArrivalsCard from "./NewArrivalsCard";
import "./NewArrivals.css";
import { Link } from "react-router-dom";

const NewArrivals=() => {
    return (
        <>
            <section className="newArrivals">
                <div className="container-fluid">
                    <div className="newArrivalsHeading d-flex mb-3 justify-content-between mx-lg-5">
                        <div className="newArrivalsHeadingLeft d-flex">
                            <img className = "me-2" src="./assets/images/arrivals/new.png" alt="" />
                            <h2 className="fs-3 fw-bolder mt-1">New Arrivals </h2>
                        </div>
                        <Link className="text-decoration-none" to = "/category/newArrivals/products">  {/*.replace(/ /g, "")*/}
                            <div className="newArrivalsHeadingRight text-end d-flex mt-1">
                                <span>View All</span>
                                <div className="ms-1">
                                    <i className="fa-solid fa-caret-right" />
                                </div>
                            </div>
                        </Link>
                    </div>

                    <NewArrivalsCard />
                </div>
            </section>
        </>
    )
}

export default NewArrivals;