import React from 'react';
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getTotals } from "../../reduxStore/cartSlice";
import Navbar from "./Navbar";

const Search = () => {
    const dispatch = useDispatch();
    const cart = useSelector((state) => state.cart);

    // console.log(cart.cartTotalQuantity)

    useEffect(() => {
        dispatch(getTotals());
    }, [cart, dispatch]);

    return (
        <>
            <nav className="navbar bg-white shadow">
                <div className ="container-fluid mx-lg-5 mt-1 position-relative">
                    <Link className="navbar-brand me-lg-5" to="/">
                        <img className="img-fluid h-25 w-50" src='/assets/images/srmInternationalLogo2.png' alt="Logo" />
                    </Link>
                    <div className="search-box d-flex w-75 top-50 start-50 translate-middle position-absolute ms-lg-2 " role="search">
                        <input className="form-control border-0 shadow-none w-75 mb-1 ms-4" type="search" placeholder="Search..." aria-label="Search" />
                        <span className="ms-lg-2 ms-xl-4 ms-xxl-5"></span>
                        <div className="text-center pt-1 mt-1">{window.innerWidth < 600 ? <i className="fa fa-chevron-down ms-1" /> :  <>All Category<i className = "fa fa-chevron-down ms-2" /></>}</div>
                        <span className=""></span>
                        <button className="border-0 bg-white my-2 me-2" type="submit"><i className="fa fa-search fs-5 opacity-50 px-3 pe-1"/></button>
                    </div>
                    <div className="d-flex">
                        <Link to="/profile" className={`${window.innerWidth < 600 ? `position-absolute top-0 start-50 ms-5 ps-5 mt-3` : ""}`} ><i className="fa fa-user text-center rounded-circle me-4 icon-circle text-dark" /></Link>
                        <Link to="/cart" className={`${window.innerWidth < 600 ? `position-absolute top-0 end-0 me-5 pe-1` : ""}`}>
                            <div className="cart">
                                <i className="fa-solid fa-cart-shopping text-center rounded-circle position-relative me-0 icon-circle text-dark">
                                    <span className="position-absolute top-0 translate-middle badge border border-3 border-white rounded-circle bg-danger ms-2 mt-1">
                                        {cart.cartTotalQuantity === 0 ? "" : <span className="px-1" style={{ fontSize: "10px" }}>{cart.cartTotalQuantity}</span>}
                                    </span>
                                </i>
                            </div>
                        </Link>
                    </div>
                </div>
                <Navbar />
            </nav>
        </>
    )
}

export default Search;