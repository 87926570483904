import React from 'react';
import { NavLink } from 'react-router-dom';
import './Categories';
import Categories from './Categories';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const Navbar = () => {
    return (
        <>
            <div className={`navbar-expand-lg navbar-expand-md bg-white pb-0 container-fluid mx-lg-5 ${window.innerWidth < 600 ? `mt-3 position-relative` : ""}`}>
                {/* <a className="navbar-brand" href="#">Navbar</a> */}

                <button className={`navbar-toggler border-0 ps-0 ${window.innerWidth < 600 ? `shadow-none` : ""}`} type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                    <span className={`navbar-toggler-icon ${window.innerWidth < 600 ? `position-absolute` : ""}`} />
                </button>
                <div className={`collapse navbar-collapse pb-0 ${window.innerWidth < 600 ? `mt-3` : ""}`} id="navbarNavDropdown">
                    <div className="navbar-nav me-auto mt-4">
                        <li className="nav-item dropdown">
                            <div className="d-flex text-dark nav-link h-75 bg-light px-3 rounded" role="button" data-bs-toggle="dropdown" aria-expanded="false">

                                <span className={`fa-solid fa-border-all me-3 pb-3 fs-3 ${window.innerWidth < 600 ? `mt-1` : ""}`}></span>

                                <h6 className="mt-1">Categories  <i className="fa fa-chevron-down ms-2" /></h6>
                            </div>

                            <Categories />

                            {/* <ul className="dropdown-menu shadow">
                                        <li><a className="dropdown-item" href="#">Action</a></li>
                                        <li><a className="dropdown-item" href="#">Another action</a></li>
                                        <li><a className="dropdown-item" href="#">Something else here Something else here</a></li>
                                    </ul> */}
                        </li>
                    </div>
                    <ul className="navbar-nav mt-1">
                        <li className="nav-item"><NavLink to='/' className="nav-link text-dark" aria-current="page">Home</NavLink></li>
                        <li className="nav-item ms-xxl-4 ms-xl-4 ms-l-4 ms-md-4"><NavLink to='/login' className="nav-link text-dark">Login</NavLink></li>
                        <li className="nav-item ms-xxl-4 ms-xl-4 ms-l-4 ms-md-4"><NavLink to='/register' className="nav-link text-dark">Register</NavLink></li>
                        <li className="nav-item ms-xxl-4 ms-xl-4 ms-l-4 ms-md-4"><NavLink to='/trackMyOrder' className="nav-link text-dark">Track My Order</NavLink></li>
                    </ul>
                </div>
            </div>
        </>
    )
}

export default Navbar