import React from 'react';
import { Link } from 'react-router-dom';
import FlashCard from './FlashCard';
import './FlashDeals.css';

const FlashDeals=() => {
    return (
        <>
            <section className="flash mt-0">
                <div className="container-fluid">
                    <div className="flashHeading d-flex justify-content-between mx-lg-5">
                        <div className="flashHeadingLeft d-flex">
                            <i className="fa fa-bolt fs-5 mt-md-2 mt-1 me-2"/>
                            <h2 className="fs-3 fw-bolder mt-0">Flash Deals</h2>
                        </div>
                        <Link className="text-decoration-none" to = "/category/flashDeals/products">  {/*.replace(/ /g, '')*/}
                            <div className="flashHeadingRight text-end d-flex">
                                <span className="">View All</span>
                                <div className="ms-1">
                                    <i className="fa-solid fa-caret-right"/>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="col-10 col-md-11 col-lg-11 col-xl-11 col-xxl-11 mx-auto">
                        <FlashCard />
                    </div>
                </div>
            </section>
        </>
    )
}

export default FlashDeals;