import React from 'react';
import './HomePageSlider.css';
import Slider from './Slider';

const Home = ({ ContentData, Loading, Error }) => {
  return (
    <>
        <section className='carousel1' style={{marginTop:'120px'}}>
            <div className="col-12">
                  {/* <Categories /> */}
                  <Slider ContentData={ContentData} Loading={Loading} Error={Error} />
            </div>
        </section>
    </>
  )
}

export default Home