import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Tdata from "./Tdata";
import useFetch from "../../utilities/useFetch";
import { Link } from "react-router-dom";

const baseUrl = process.env.REACT_APP_BASE_URL;


const TopCategoryCard = () => {
    const { data: CategoryData, loading, error } = useFetch(`${baseUrl}/category`);

    const settings = {
        dots: false,
        infinite: true,
        arrows: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,

        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 600,
                settings: {
                    infinite: true,
                    dots: false,
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    // initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    infinite: true,
                    dots: false,
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    }

    const categories = CategoryData?CategoryData.filter(items => items.componentId > 2):[];

    if (error) console.log(error);

    return (
        <>
            <Slider {...settings} className="mx-lg-5">
                {categories ?
                    categories.map((value) => {
                        return (
                            <div className="col-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3" key={value.componentId}>
                                <Link className="" to={`/category/${value.name.replace(/ /g, "")}/${value.componentId}/products`}>
                                    <div className="product1 mx-2">
                                        <div className="nametop d-flex">
                                            <span className="tleft col-5 text-center">{value.name}</span>
                                            <span className="tright col-5">{value.desc}</span>
                                        </div>
                                        <div className="img">
                                            <img src={value.photo ? value.photo : "/assets/images/top/category-1.png"} alt="" />
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        )
                    })
                    : (loading ? <p>Loading....</p> : <p>Unexpected error occured...</p>)

                }
            </Slider>
        </>
    )
}

export default TopCategoryCard;