import React from 'react';
import { Link } from 'react-router-dom';
import "./Login.css";

const ForgetPassword = () => {
    return (
        <>
            <div className="container-fluid">
                <div className="wrapper">
                    <div className="logo">
                        <img
                            src="https://www.freepnglogos.com/uploads/twitter-logo-png/twitter-bird-symbols-png-logo-0.png"
                            alt=""
                        />
                    </div>
                    <div className="text-center mt-4 name">E-commerce</div>
                    <form className="p-3 mt-3">
                            <div className="form-field d-flex align-items-center">
                                <i className="far fa-user ms-2" />
                                <input type="username" placeholder="Email or Username" />
                            </div>
                        
                        <Link to="/recoveryPage">
                            <button className="btn mt-3">Find Username</button>
                        </Link>
                    </form>
                </div>
            </div>
        </>
    )
}

export default ForgetPassword;