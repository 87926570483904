import { createSlice } from "@reduxjs/toolkit";

const baseUrl = process.env.REACT_APP_BASE_URL;  

export const STATUSES = Object.freeze({
    SUCCESS: "success",
    ERROR: "error",
    LOADING: "loading"
});

const productSlice = createSlice({
    name: "product",
    initialState: {
        data: [],
        status: STATUSES.SUCCESS,
    },

    reducers: {
         setProducts(state, action) {
            state.data = action.payload;
        },
        setStatus(state, action) {
            state.status = action.payload;
        },
    },
});


export function fetchProducts() {
    return async function fetchProductThunk(dispatch, getState) {
        dispatch(setStatus(STATUSES.LOADING));
        try {
            const res = await fetch(`${baseUrl}/product`); //'https://srm-international.net/api/product'
            const data = await res.json();
            dispatch(setProducts(data));
            console.log(data)
            dispatch(setStatus(STATUSES.SUCCESS));
        } catch (err) {
            console.log(err);
            dispatch(setStatus(STATUSES.ERROR));
        }
    };
}


export const { setProducts, setStatus } = productSlice.actions;
export default productSlice.reducer;