import React from 'react';

const ProductDescription = ({data}) => {
    return (
        <>
            <div className="d-flex align-items-center">
                <i className="fa fa-calendar-check" />
                <span className="ms-1">Delivery from sweden, 15-45 days</span>
            </div>
            <div className="mt-2">
                <span className="fw-bold">Description</span>
                <p>
                    The minimalist collaboration features chairs, lightening, Shelves,
                    Sofas, Desks and various home accessories, all offering form and
                    function at the same point.We use high-strength clamps and joinery
                    techniques specially designed for engineered wood beds. Ergo, no
                    irksome creaks - and you can sleep like a baby, well into
                    adulthood!
                </p>
                <div className="bullets">
                    <div className="d-flex align-items-center">
                        <span className="dot" />
                        <span className="bullet-text">Best in Quality</span>
                    </div>
                    <div className="d-flex align-items-center">
                        <span className="dot" />
                        <span className="bullet-text">Anti-creak joinery</span>
                    </div>
                    <div className="d-flex align-items-center">
                        <span className="dot" />
                        <span className="bullet-text">Sturdy laminate surfaces</span>
                    </div>
                    <div className="d-flex align-items-center">
                        <span className="dot" />
                        <span className="bullet-text">Relocation friendly design</span>
                    </div>
                    <div className="d-flex align-items-center">
                        <span className="dot" />
                        <span className="bullet-text">High gloss, high style</span>
                    </div>
                    <div className="d-flex align-items-center">
                        <span className="dot" />
                        <span className="bullet-text">
                            Easy-access hydraulic storage
                        </span>
                    </div>
                </div>
            </div>
            <div className="mt-2">
                <span className="fw-bold">Store</span>
            </div>
            <div className="d-flex align-items-center">
                <img src="/assets/images/flash/flash-2.png" alt="" className="rounded-circle store-image bg-dark" />
                <div className="d-flex flex-column ms-2 comment-profile">
                    <div className="comment-ratings">
                        <i className="fa fa-star me-1" />
                        <i className="fa fa-star me-1" />
                        <i className="fa fa-star me-1" />
                        <i className="fa fa-star me-1" />
                    </div>
                    <span className="username">Rare Boutique</span>
                    <small className="followers">25K Followers</small>
                </div>
            </div>
        </>
    )
}

export default ProductDescription