import { createSlice } from "@reduxjs/toolkit";

const cartSlice = createSlice({
    name: "cart",
    initialState: {
        cartProducts: localStorage.getItem("cartProducts") ? JSON.parse(localStorage.getItem("cartProducts")) : [],
        cartTotalQuantity: 0,
        cartTotalAmount: 0,
    },
    reducers: {
        addProductToCart: (state, action) => {
            // if product alredy exist or not in the cart 
            const existingProduct = state.cartProducts.findIndex((product) => product.componentId === action.payload.componentId);

            if (existingProduct >= 0) {
                state.cartProducts[existingProduct] = {
                    ...state.cartProducts[existingProduct],
                    cartQuantity: state.cartProducts[existingProduct].cartQuantity + 1,
                }
            } else {
                // but if the product doesnt exit in the cart that mean if card is empty
                // then new product is added in cart  and its qty is initalize to 1
                let tempProductItem = { ...action.payload, cartQuantity: 1 };
                state.cartProducts.push(tempProductItem);
            }
            // state.quantity += 1;
            // state.products.push(action.payload);
            // state.total += action.payload.price * action.payload.quantity;
            localStorage.setItem("cartProducts", JSON.stringify(state.cartProducts));
        },

        decreaseCart(state, action) {
            const existingProduct = state.cartProducts.findIndex((product) => product.componentId === action.payload.componentId);

            if (state.cartProducts[existingProduct].cartQuantity > 1) {
                state.cartProducts[existingProduct].cartQuantity -= 1;

                // toast.info("Decreased product quantity", {
                //     position: "bottom-left",
                // });
            } else if (state.cartProducts[existingProduct].cartQuantity === 1) {
                const nextCartItems = state.cartProducts.filter((item) => item.componentId !== action.payload.componentId);

                state.cartProducts = nextCartItems;

                // toast.error("Product removed from cart", {
                //     position: "bottom-left",
                // });
            }

            localStorage.setItem("cartProducts", JSON.stringify(state.cartProducts));
        },

        removeProductFromCart(state, action) {
            state.cartProducts.map((cartItem) => {
                if (cartItem.componentId === action.payload.componentId) {
                    const nextCartItems = state.cartProducts.filter((item) => item.componentId !== cartItem.componentId);

                    state.cartProducts = nextCartItems;

                    // toast.error("Product removed from cart", {
                    //     position: "bottom-left",
                    // });
                }
                localStorage.setItem("cartProducts", JSON.stringify(state.cartProducts));
                return state;
            });
        },

        getTotals(state, action) {
            let { total, quantity } = state.cartProducts.reduce((cartTotal, cartItem) => {
                const { salePrice, cartQuantity } = cartItem;
                const itemTotal = salePrice * cartQuantity;

                cartTotal.total += itemTotal;
                cartTotal.quantity += cartQuantity;

                return cartTotal;
            },
                {
                    total: 0,
                    quantity: 0,
                }
            );
            total = parseFloat(total.toFixed(2));
            state.cartTotalQuantity = quantity;
            state.cartTotalAmount = total;
        },

        clearCart(state, action) {
            state.cartProducts = [];
            localStorage.setItem("cartProducts", JSON.stringify(state.cartProducts));
            // toast.error("Cart cleared", { position: "bottom-left" });
        },
    },
});

export const { addProductToCart, decreaseCart, removeProductFromCart, getTotals, clearCart } = cartSlice.actions;
export default cartSlice.reducer;