import React from 'react';
import { Link } from 'react-router-dom';
import './Login.css';

const Register = () => {
    return (
        <>
            <div className="container-fluid">
                <div className="wrapper mx-auto mt-3">
                    <div className="logo text-center mt-3">
                        <img src="/assets/images/srmInternationalLogo.jpg" alt="Logo" />
                    </div>
                    {/* <div className="text-center mt-4 name">E-commerce</div> */}
                    <form className="p-3 mt-3">
                        <div className="form-field d-flex align-items-center">
                            <i className="far fa-user ms-2" />
                            <input type="text" name="userName" id="userName" placeholder="Username" />
                        </div>
                        <div className="form-field d-flex align-items-center">
                            <i className="fa-solid fa-mobile-screen-button text-secondary ms-2" />
                            <input type="number" name="mobileNumber" id="mobileNumber" placeholder="Mobile Number" />
                        </div>
                        <div className="form-field d-flex align-items-center">
                            <i className="fa-regular fa-envelope text-secondary fs-5 ms-2" />
                            <input type="email" name="email" id="email" placeholder="E-mail" />
                        </div>
                        <div className="form-field d-flex align-items-center">
                            <i className="fas fa-key ms-2" />
                            <input type="password" name="password" id="password" placeholder="Password" />
                        </div>
                        <button className="btn mt-3">Sign Up</button>
                    </form>
                    <div className="text-center fs-5">
                        <Link to="" className='fs-6 text-decoration-none'>Already have an account ?</Link> or
                        <Link to="/login" className='fs-6 text-decoration-none'> Login</Link>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Register