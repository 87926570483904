import React from 'react';
import { Link } from "react-router-dom";
import "./Login.css";

const Login = () => {
    return (
        <>
            <div className="container-fluid">
                <div className="wrapper mx-auto mt-5">
                    <div className="logo text-center mt-5">
                        <img src="/assets/images/srmInternationalLogo.jpg" alt="Logo" />
                    </div>
                    {/* <div className="text-center mt-4 name">E-commerce</div> */}
                    <form className="p-3 mt-3">
                        <div className="form-field d-flex align-items-center">
                            <i className="far fa-user ms-2" />
                            <input type="text" name="userName" id="userName" placeholder="Username" />
                        </div>
                        <div className="form-field d-flex align-items-center">
                            <i className="fas fa-key ms-2" />
                            <input type="password" name="password" id="pwd" placeholder="Password" />
                        </div>
                        <button className="btn mt-3">Login</button>
                    </form>
                    <div className="text-center fs-6">
                        <Link to="/forgetPassword" className="text-decoration-none">Forget password ?</Link> or <Link to="/register" className="text-decoration-none">Sign up</Link>
                    </div>
                </div>
            </div>
            
        </>
    )
}

export default Login;