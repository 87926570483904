import React from 'react'
import CartDetails from '../component/cart/CartDetails'
import Header from '../component/header/Header'

const Cart = ({ CartItem, addToCart, decreaseQty }) => {
  return (
      <>
          <Header CartItem={CartItem} />
          <CartDetails CartItem={CartItem} addToCart={addToCart} decreaseQty={decreaseQty} />
      </>
  )
}

export default Cart