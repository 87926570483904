import React from 'react';
import SlideCard from './SlideCard';

const Slider = ({ ContentData, Loading, Error }) => {
  return (
    <>
      <section className="homeSlide pb-0">
        <div className="w-100">  {/* w-100 carousel carousel-dark slide mt-md-5 margin-for-header */}
          <SlideCard ContentData={ContentData} Loading={Loading} Error={Error} />
        </div>
      </section>
    </>
  )
}

export default Slider;