import React  from 'react';
import "./SingleProduct.css";

const SimilarProducts = ({data}) => {
    return (
        <>
            <div className="singleProductCard mt-2">
                <span>Similar Items:</span>
                <div className="similar-products mt-2 d-flex">
                    <div className="singleProductCard border rounded p-1" style={{ width: "9rem", marginRight: 3 }} >
                        <img src="/assets/images/flash/flash-2.png" className="card-img-top" alt="..." />
                        <div className="card-body">
                            <h6 className="card-title">{"\u09F3"} 1,999</h6>
                        </div>
                    </div>
                    <div className="singleProductCard border rounded p-1" style={{ width: "9rem", marginRight: 3 }} >
                        <img src="/assets/images/flash/flash-2.png" className="card-img-top" alt="..." />
                        <div className="card-body">
                            <h6 className="card-title">{"\u09F3"} 1,699</h6>
                        </div>
                    </div>
                    <div className="singleProductCard border rounded p-1" style={{ width: "9rem", marginRight: 3 }} >
                        <img src="/assets/images/flash/flash-2.png" className="card-img-top" alt="..." />
                        <div className="card-body">
                            <h6 className="card-title">{"\u09F3"} 2,999</h6>
                        </div>
                    </div>
                    <div className="singleProductCard border rounded p-1" style={{ width: "9rem", marginRight: 3 }} >
                        <img src="/assets/images/flash/flash-2.png" className="card-img-top" alt="..." />
                        <div className="card-body">
                            <h6 className="card-title">{"\u09F3"} 3,999</h6>
                        </div>
                    </div>
                    <div className="singleProductCard border rounded p-1" style={{ width: "9rem" }}>
                        <img src="/assets/images/flash/flash-2.png" className="card-img-top" alt="..." />
                        <div className="card-body">
                            <h6 className="card-title">{"\u09F3"} 999</h6>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SimilarProducts