import React from "react";
import Ndata from "./Ndata";
import useFetch from "../../utilities/useFetch";
import { Link } from "react-router-dom";

const baseUrl = process.env.REACT_APP_BASE_URL;


const NewArrivalsCard = () => {
    const { data: productItems, loading, error } = useFetch(`${baseUrl}/product`);

    const products = productItems?productItems.filter(items => items.componentId > 2 && items.componentId < 9) :[];

    if (error) console.log(error);

    return (
        <>
            <div className="content row g-1 g-md-4 newArrivalProduct mx-lg-5 d-flex h-100">
                {productItems ?
                    products.map((val) => {
                        return (
                            <div className="box col-4 col-md-2 mx-auto border p-2 rounded newArrivalProduct" key={val.componentId}>
                                <Link className="text-decoration-none text-black" to={`/category/newArrival/${val.uniqueCode}/products/product/${val.componentId}`}>
                                <div className="img mx-auto">
                                    <img className="img-fluid rounded mx-auto" src={val.photo ? `${baseUrl}/uploads/${val.photo}` : `/assets/images/arrivals/arrivals1.png`} alt="" />
                                </div>
                                <h4 className="fs-6 mt-1 fw-normal">{val.itemName}</h4>
                                    <span className="fw-bold price">{"\u09F3"} {val.salePrice}</span>
                                </Link>
                            </div>
                        )
                    })
                    : (loading ? <p>Loading...</p> : <p>Unexpected error occured...</p>)

                }
            </div>
        </>
    )
}

export default NewArrivalsCard;