import React from 'react';
import { useState } from "react";
import { Link, useParams } from "react-router-dom";
import Header from "../component/header/Header";
import "./Products.css";
import { useDispatch, useSelector } from "react-redux";
import { addProductToCart } from "../reduxStore/cartSlice";
// import { fetchProducts, STATUSES } from "../reduxStore/productSlice";
import useFetch from "../utilities/useFetch";
import Footer from "../component/footer/Footer";

const baseUrl = process.env.REACT_APP_BASE_URL;

const Products = () => {

    // const styleObj = {
    //     fontSize: 14,
    //     color: "#4a54f1",
    //     textAlign: "center",
    //     // paddingTop: "100px",
    //     marginTop: "250px",
    // }
    const params = useParams();
    const { categoryName } = params;
    const { categoryId } = params;
    const { data: productItems, loading, error } = useFetch(`${baseUrl}/product?categoryId=${categoryId}`);

    const [count, setCount] = useState(0)
    const increment = () => {
        setCount(count + 1)
    }

    // Destructuring
    // const { productItems } = Data;

    const dispatch = useDispatch();
    // const { data: productItems, status } = useSelector((state) => state.product);

    // useEffect(() => {
    //     dispatch(fetchProducts());
    // }, [dispatch]);

    const handleAddToCart = (product) => {
        dispatch(addProductToCart(product));
        // navigate("/cart");
    };

    if (error) console.log(error);

    return (
        <>

            <Header />
            <main >
                <div className="container-fluid col-10 col-md-11 col-lg-11 col-xl-11 col-xxl-11 mb-5" style={{ marginTop: "140px" }}>
                    <div className="heading">
                        <div className="heading-left d-flex mb-4">
                            <i className="fa fa-bolt fs-5 mt-2" />
                            <h2 className="fs-3 fw-bolder mt-0">All {categoryName.split(/(?=[A-Z])/).join(' ')} Product List</h2>
                        </div>
                    </div>
                    <div className="row g-4">
                        {productItems ?
                            <>
                                {productItems.map((productItem) => {
                                    return (
                                        // productItem.categoryName.toUpperCase().replace(/ /g, "") === name.toUpperCase() &&

                                        <div className="col-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3 h-100" key={productItem.componentId}>
                                            <div className="product h-100">
                                                <Link className="text-decoration-none text-black" to={`product/${productItem.componentId}`}>
                                                    <div className="h-75 w-100">
                                                        {productItem.discount ?
                                                            <span className="discount">{productItem.discount}% Off</span>
                                                            : ""
                                                        }
                                                        <div className="h-75 w-75">
                                                            <img className="img-fluid ms-xxl-4 ms-xl-4 ms-lg-4 ms-md-4 ms-4" style={{ height: "120px" }} src={productItem.photo ? `${baseUrl}/uploads/${productItem.photo}` : '/assets/images/flash/flash-1.png'} alt="cover" />
                                                        </div>
                                                        {/* <div className="product-like">
                                                            <label htmlFor="">{count}</label> <br />
                                                            <div className="product-action-vertical">
                                                                <div className="btn-product-icon btn-wishlist btn-expandable">
                                                                    <i className="fa-regular fa-heart" onClick={increment} title="Add to wishlist" /><span>Add to wishlist</span>
                                                                </div>
                                                            </div>
                                                        </div> */}
                                                    </div>

                                                    <div className="produt-details mt-3">
                                                        <h3 style={{height: "30px"}}>{productItem.itemName}</h3>
                                                        <div className="rate">
                                                            <i className="fa fa-star" />
                                                            <i className="fa fa-star" />
                                                            <i className="fa fa-star" />
                                                            <i className="fa fa-star" />
                                                            <i className="fa fa-star" />
                                                        </div>
                                                    </div>
                                                </Link>
                                                <div className="price h-25">
                                                    <h4 className="fs-6 fw-bold mt-3" >{"\u09F3"} {productItem.salePrice}</h4>
                                                    <button className="mt-2" onClick={() => handleAddToCart(productItem)} title="Add to cart">
                                                        <i className="fa fa-plus" />
                                                    </button>
                                                </div>

                                            </div>
                                        </div>
                                        // :
                                        // <div style={styleObj} key={index}>
                                        //     <h1>This is {name} category</h1>
                                        // </div>
                                    )
                                })}

                            </>
                            : (loading ? <p>Loading...</p> : <p>Unexpected error occured...</p>)
                        }
                    </div>
                </div>
            </main>
            <Footer />
        </>
    )
}

export default Products;