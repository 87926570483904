import React, { useState } from "react";
import { Routes, Route } from "react-router-dom";
import './App.css';
import Cart from "./pages/Cart";
import Products from "./pages/Products";
import ForgetPassword from "./pages/ForgetPassword";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Profile from "./pages/Profile";
import RecoveryEmailSentPage from "./pages/RecoveryEmailSentPage";
import Register from "./pages/Register";
import Product from "./pages/Product";
import useFetch from "./utilities/useFetch";

const baseUrl = process.env.REACT_APP_BASE_URL;

const App = () => {
  const { data: ContentData, loading: Loading, error: Error } = useFetch(`${baseUrl}/content`);
  const codesFetch = useFetch(`${baseUrl}/code`);
  
    if (Error) console.log(Error);

  return (
    <>
      <Routes>
        <Route path="/" element={<Home codesFetch={codesFetch} ContentData={ContentData} Loading={Loading} Error={Error} />} />
      
        <Route exact path="/cart" element={<Cart />} />
      
        <Route exact path="/category/:categoryName/:subCategoryName/:categoryId" element={<Products />} />
        <Route exact path="/category/:name/:categoryId/products/product/:id" element={<Product />} />

        <Route exact path="/profile" element={<Profile />} />
      
      
        <Route exact path="/login" element={<Login />} />
      
      
        <Route exact path="/forgetPassword" element={<ForgetPassword />} />
      
      
        <Route exact path="/recoveryPage" element={<RecoveryEmailSentPage />} />
      
      
        <Route exact path="/register" element={<Register />} />
      </Routes>
    </>
  );
}

export default App;
