import React from 'react';
import { useParams } from "react-router-dom";
import Footer from "../component/footer/Footer";
import Header from "../component/header/Header";
import ProductByeOrAddToCartDetails from "../component/singleProduct/ProductByeOrAddToCartDetails";
import ProductDescription from "../component/singleProduct/ProductDescription";
import ProductReviews from "../component/singleProduct/ProductReviews";
import SimilarProducts from "../component/singleProduct/SimilarProducts";
import SingleProductImageSlider from "../component/singleProduct/SingleProductImageSlider";
// import "../component/singleProduct/SingleProduct.css";
import useFetch from "../utilities/useFetch";

const baseUrl = process.env.REACT_APP_BASE_URL;


const Product = () => {

    const params = useParams();
    const { id } = params;
    const { data, loading, error } = useFetch(`${baseUrl}/product/${id}`);

    if (error) console.log(error);

    return (

        <>
            <Header />
            {data ?
                <div className="container-fluid mb-3" style={{ marginTop: "120px" }}>
                    <div className="row no-gutters">
                        <div className="col-md-5">
                            {/* Product Image Slider */}
                            <SingleProductImageSlider data={data} />
                            {/* Product Reviews */}
                            <ProductReviews data={data} />
                        </div>
                        <div className="col-md-7">
                            {/* Product Bye or Add to cart details */}
                            <ProductByeOrAddToCartDetails data={data} />
                            {/* Similar Products */}
                            <SimilarProducts data={data} />
                        </div>
                    </div>
                </div>
                : (loading ? <p>Loading...</p> : <p>Unexpected error occured...</p>)

            }
            <Footer />
        </>

    )
}

export default Product;