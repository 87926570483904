import React, { Component } from 'react';
import { Link } from "react-router-dom";
import "./Footer.css";

const Footer = ({ codesFetch}) => {
    console.log(codesFetch);
    console.log('Codes....')
    const codes = codesFetch.data;
    const address = codes ? codes.filter(items => items.type === "address") : [];
    const footerLeftsideContent = codes ? codes.filter(items => items.uniqueCode === "footer.description") : [];
    // console.log(ContentData);
    if (codesFetch.error) console.log(codesFetch.error);

    return (
        <>
            <footer className="text-center text-lg-start">
                {/* Section: Social media */}
                <section className="border-bottom">
                    <div className="container-fluid">
                        <div className="d-flex justify-content-center justify-content-lg-between py-4 mx-lg-5">
                            <div className="d-none d-lg-block">
                                <span>Get connected with us on social networks:</span>
                            </div>
                            <div className="me-lg-2">
                                <Link to="" className="me-4 text-reset"><i className="fab fa-facebook-f" /></Link>
                                <Link to="" className="me-4 text-reset"><i className="fab fa-twitter" /></Link>
                                {/* <Link to="" className="me-4 text-reset"><i className="fab fa-google" /></Link> */}
                                <Link to="" className="me-4 text-reset"><i className="fab fa-instagram" /></Link>
                                {/* <Link to="" className="me-4 text-reset"><i className="fab fa-linkedin" /></Link> */}
                                {/* <Link to="" className="text-reset"><i className="fab fa-github" /></Link> */}
                                <Link to="" className="text-reset"><i className="fab fa-youtube"/></Link>
                            </div>
                        </div>
                    </div>
                </section>
                {/* Section: Social media */}
                {/* Section: Links  */}
                <section className="">
                    <div className="container-fluid text-center text-md-start mt-5 ms-md-4">
                        <div className="row mt-3">
                            <div className="col-md-3 col-lg-4 col-xl-3 mx-auto">
                                {/* Content */}
                                <Link to="/" className="text-decoration-none rounded-3">
                                    <div className="h-25 w-100">
                                        <img className="img-fluid w-75 rounded-3 mb-2" src='/assets/images/srmInternationalLogo.jpg' alt="Logo" />
                                    </div>
                                    {/* <h2 className="text-uppercase fw-bold mb-4"><i className="fas fa-gem me-3" />E-commerce</h2> */}
                                </Link>
                                {footerLeftsideContent ?
                                    footerLeftsideContent.map((i) => {
                                        return(
                                        <p className="mb-0 pb-0" key={i.componentId}>{i.value}</p>
                                        )
                                    })
                                    : (codesFetch.loading ? <p>Loading....</p> : <p>Unexpected error occured...</p>)

                                }
                                {/* <div className=''>
                                    <div className='img'>
                                        <i className='fa-brands fa-google-play me-3'/>
                                        <span>Google Play</span>
                                    </div>
                                    <div className='img mt-4'>
                                        <i className='fa-brands fa-app-store-ios me-3'/>
                                        <span>App Store</span>
                                    </div>
                                </div> */}
                            </div>
                            <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4 mb-md-0">
                                {/* Links */}
                                <h6 className="text-uppercase fw-bold mb-4">About Us</h6>
                                <p><Link to="#!" className="text-reset text-decoration-none">Careers</Link></p>
                                {/* <p><Link to="#!" className="text-reset text-decoration-none">Our Stores</Link></p>
                                <p><Link to="#!" className="text-reset text-decoration-none">Our Cares</Link></p> */}
                                <p><Link to="#!" className="text-reset text-decoration-none">Terms & Conditions</Link></p>
                                <p><Link to="#!" className="text-reset text-decoration-none">Privacy Policy</Link></p>
                            </div>
                            <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4 mb-md-0">
                                {/* Links */}
                                <h6 className="text-uppercase fw-bold mb-4">Customer Care</h6>
                                <p><Link to="#!" className="text-reset text-decoration-none">Help Center</Link></p>
                                <p><Link to="#!" className="text-reset text-decoration-none">How to Buy</Link></p>
                                <p><Link to="#!" className="text-reset text-decoration-none">Track Your Order</Link></p>
                                {/* <p><Link to="#!" className="text-reset text-decoration-none">Corporate & Bulk Purchasing</Link></p> */}
                                <p><Link to="#!" className="text-reset text-decoration-none">Returns & Refunds</Link></p>
                            </div>
                            <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-4 mb-md-0" >
                                <h6 className="text-uppercase fw-bold mb-4">Contact Us</h6>
                                {address?address.map(
                                    (item) => {
                                    return (
                                            <p key={item.componentId}><i className="" /> {item.uniqueCode}: {item.value}</p>
                                            )
                                        })
                                        : (codesFetch.loading ? <p>Loading....</p> : <p>Unexpected error occured...</p>)
                                    }
                            </div>
                                   
                        </div>
                    </div>
                </section>
                {/* Section: Links  */}
                {/* Copyright */}
                <div className="text-center p-2" style={{ backgroundColor: "rgba(0, 0, 0, 0.05)" }}>
                    © {(new Date().getFullYear())} Copyright :
                    <Link className="text-reset text-decoration-none fw-bold ms-3" to="/">SRM-International</Link>
                </div>
                {/* Copyright */}
            </footer>
            {/* Footer */}
        </>


    )
}

export default Footer;