import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const baseUrl = process.env.REACT_APP_BASE_URL;

const SlideCard = ({ ContentData, Loading, Error }) => {

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };

  const sliderContent = ContentData ? ContentData.filter(items => items.type === "Slider") : [];

  if (Error) console.log(Error);

  return (
    <>
      <Slider {...settings}>
        {sliderContent ?
          sliderContent.map((value) => {
            return (
              <div className="position-relative overflow-hidden" key={value.componentId}>
                <div className={`col-7 left mt-2 ms-2 position-absolute ${window.innerWidth > 600 ? `top-50` : ""}`}>
                  <h5 className="fw-bolder">{value.title}</h5>
                  <p className="my-3" style={{fontSize:`${window.innerWidth < 600 ? `12px` : ""}`}}>{value.subTitle}</p>
                  <button className="btn btn-primary border-0 fw-bold mt-md-4 mt-l-4 mt-xl-4 mt-xxl-4 py-2 px-4">Visit Collections</button>
                </div>
                <div className="" >
                  <img className="img-fluid w-100" src={`${baseUrl}/uploads/${value.photo}`} style={{height:`${window.innerWidth > 600 ? `400px` : `210px`}`}} alt="cover" />
                </div>
              </div>
            )
          })
          : (Loading ? <p>Loading....</p> : <p>Unexpected error occured...</p>)
        }
      </Slider>
    </>
  )
}

export default SlideCard;