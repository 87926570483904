import React from "react"
import TopCategoryCard from "./TopCategoryCard"
import "./TopCategory.css";
import { Link } from "react-router-dom";

const TopCategory=() => {
    return (
        <>
            <section className="topCategory">
                <div className="container-fluid">
                    <div className="topCategoryHeading d-flex justify-content-between mx-lg-5 mt-4">
                        <div className="topCategoryHeadingLeft d-flex">
                            <i className="fa-solid fa-border-all fs-2 mt-md-1 me-2"/>
                            <h2 className="fs-3 fw-bolder mt-md-1">Top Categories</h2>
                        </div>
                        <Link className="text-decoration-none" to = "/category/topCategories/products">  {/*.replace(/ /g, "")*/}
                            <div className="topCategoryHeadingRight text-end d-flex mt-1">
                                <span className="">View All</span>
                                <div className="ms-1">
                                    <i className="fa-solid fa-caret-right" />
                                </div>
                            </div>
                        </Link>
                    </div>
                    <TopCategoryCard />
                </div>
            </section>
        </>
    )
}

export default TopCategory