import React, { Component }  from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addProductToCart, clearCart, decreaseCart, getTotals, removeProductFromCart } from '../../reduxStore/cartSlice';
import './CartDetails.css';

const baseUrl = process.env.REACT_APP_BASE_URL;

const CartDetails = ({ CartItem, addToCart, decreaseQty }) => {
    const dispatch = useDispatch();
    const cart = useSelector((state) => state.cart);


    useEffect(() => {
        dispatch(getTotals());
    }, [cart, dispatch]);

    const handleAddToCart = (product) => {
        dispatch(addProductToCart(product));
    };
    const handleDecreaseCart = (product) => {
        dispatch(decreaseCart(product));
    };
    const handleRemoveFromCart = (product) => {
        dispatch(removeProductFromCart(product));
    };
    // const handleClearCart = () => {
    //     dispatch(clearCart());
    // };
   

    // prodcut qty total
    return (
        <>
            <section className='cart-items bg-light py-5' style={{ marginTop: '150px' }}>
                <div className='container-fluid d-flex px-5'>
                    <div className='cart-details col-8 col-md-8 col-lg-8 col-xl-8 col-xxl-8'>
                        {cart.cartProducts.length === 0 && <h1 className='no-items bg-white fs-5 rounded shadow position-relative m-2 p-3'>No Item has been added in this Cart</h1>}

                        {cart.cartProducts.map((item) => {
                            const totalPrice = item.salePrice * item.cartQuantity

                            return (
                                <div className='cart-list rounded mh-25 position-relative p-2 mx-5 mb-5 mt-2 d-flex bg-white shadow' key={item.componentId}>
                                    <div className='h-25'>
                                        <img className='img-fluid h-25' src={item.photo ? `${baseUrl}/uploads/${item.photo}` : '/assets/images/flash/flash-1.png'} alt=''/>
                                    </div>
                                    <div className='w-75 mt-2 d-flex'>
                                        <h3 className='fs-6 fw-bold mt-5'>{item.itemName}</h3>
                                        <h4 className='fs-6 fw-normal mt-5 text-secondary d-flex ms-3'>
                                            {'\u09F3'} {item.salePrice} * {item.cartQuantity}
                                            <span className='text-secondary mb-1 ms-3'> =</span>
                                            <span className='fw-bold ms-4'>{'\u09F3'} {totalPrice}</span>
                                        </h4>
                                    </div>
                                    <div className=''>
                                        <div className='text-end'>
                                            <button className='bg-white fs-4 me-2 border-0' onClick={() => handleRemoveFromCart(item)}>
                                                <i className='fa-solid fa-xmark' />
                                            </button>
                                        </div>

                                        {/* <div className='cartControl d-flex mt-5'>
                                            <button className='incCart fs-5 rounded m-2' onClick={() => addToCart(item)}>
                                                <i className='fa-solid fa-plus'/>
                                            </button>
                                            <button className='desCart fs-5 rounded m-2' onClick={() => decreaseQty(item)}>
                                                <i className='fa-solid fa-minus'/>
                                            </button>
                                        </div> */}
                                        <div className='cartControl d-flex mt-5 mb-0'>
                                            <button className='increment mt-5' onClick={() => handleAddToCart(item)}>
                                                <i className='fa-solid fa-plus' />
                                            </button>
                                            <button className='decrement mt-5' onClick={() => handleDecreaseCart(item)}>
                                                <i className='fa-solid fa-minus' />
                                            </button>
                                        </div>
                                    </div>

                                    <div className='cart-item-price'></div>
                                </div>
                            )
                        })}
                    </div>

                    <div className='cart-total col-4 col-md-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4 shadow'>
                        <h2>Cart Summary</h2>
                        <div className=' d-flex'>
                            <h4 className='fw-bold fs-5'>Total Price :</h4>
                            <h3 className='fw-bold fs-5 ms-3'>{'\u09F3'} {cart.cartTotalAmount}.00</h3>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}


export default CartDetails;