import React from 'react';
import BigDiscount from '../component/bigDiscount/BigDiscount';
import FlashDeals from '../component/flashDeals/FlashDeals';
import Footer from '../component/footer/Footer';
import Header from '../component/header/Header';
import HomePageSlider from '../component/homepageSlider/HomePageSlider';
import NewArrivals from '../component/newArrivals/NewArrivals';
import TopCategory from '../component/topCategory/TopCategory';

const Home=({ codesFetch, ContentData, Loading, Error }) => {
  return (
    <>
      <Header />
      <HomePageSlider ContentData={ContentData} Loading={Loading} Error={Error} />
      <FlashDeals />
      <TopCategory />
      <NewArrivals />
      <BigDiscount />
      <Footer codesFetch={codesFetch}/>
    </>
  )
}

export default Home;