import React from 'react';
import { Link } from "react-router-dom";
import "./Login.css";


const RecoveryEmailSentPage = () => {
    return (
        <>
            <div className="container-fluid">
                <div className="wrapper">
                    <div className="homeback">
                        <div className="homebox">
                            <div className="success-check">
                                <i className="fa-sharp fa-solid fa-face-smile mt-4" />
                            </div>
                            <p className="mt-3">
                                Password recovery link is sent to your e-mail successfully. Please check your inbox!
                            </p>
                            <Link to="/login">
                                <button className="btn mt-4">Go Home</button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default RecoveryEmailSentPage;