import React, { Component }  from 'react';
import { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from 'react-router-dom';
import { fetchProducts, STATUSES } from "../../reduxStore/productSlice";
import { addProductToCart } from "../../reduxStore/cartSlice";

const baseUrl = process.env.REACT_APP_BASE_URL;

const SampleNextArrow = (props) => {
    const { onClick } = props
    return (
        <div className='control-btn' onClick={onClick}>
            <button className='next border-0' >
                <i className="fa-solid fa-angle-right mt-3" />
            </button>
        </div>
    )
}
const SamplePrevArrow = (props) => {
    const { onClick } = props
    return (
        <div className='control-btn' onClick={onClick}>
            <button className='prev border-0'>
                <i className="fa-solid fa-angle-left mt-3" />
            </button>
        </div>
    )
}


const FlashCard = () => {
    const [count, setCount] = useState(0)
    const increment = () => {
        setCount(count + 1)
    }
    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,

        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: false,
                    dots: false
                }
            },
            {
                breakpoint: 600,
                settings: {
                    infinite: false,
                    dots: false,
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    // initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    infinite: false,
                    dots: false,
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    }

    // Destructuring 
    // const { productItems } = Data;

    const dispatch = useDispatch();
    // const navigate = useNavigate();

    const { data: productItems, status } = useSelector((state) => state.product);

    useEffect(() => {
        dispatch(fetchProducts());
    }, [dispatch]);

    const handleAddToCart = (product) => {
        dispatch(addProductToCart(product));
        // navigate("/cart");
    };


    // if (status === STATUSES.LOADING) {
    //     return <h2>Loading....</h2>;
    // }

    // if (status === STATUSES.ERROR) {
    //     return <h2>Something went wrong!</h2>;
    // }


    return (
        <>
            {status === STATUSES.SUCCESS ?
                <Slider {...settings} className="mx-lg-4">
                    {productItems.map((productItem, index) => {
                        return (
                            <div className="col-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3" key={productItem.componentId}>
                                <div className="product">
                                    <Link className="text-decoration-none text-black" to={`/category/flashDeals/${productItem.uniqueCode}/products/product/${productItem.componentId}`}>
                                        <div className="img">
                                            <span className="discount">{productItem.discount}% Off</span>
                                            <img className="img-fluid h-75 w-75 ms-xxl-4 ms-xl-4 ms-lg-4 ms-md-4 ms-4" src={productItem.photo ? `${baseUrl}/uploads/${productItem.photo}` : '/assets/images/flash/flash-1.png'} alt="cover" />
                                            <div className="product-like">
                                                <label htmlFor="">{count}</label> <br />
                                                <div className="product-action-vertical">
                                                    <div className="btn-product-icon btn-wishlist btn-expandable">
                                                        <i className="fa-regular fa-heart" onClick={increment} title="Add to wishlist" /><span>Add to wishlist</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="produt-details">
                                            <h3>{productItem.itemName}</h3>
                                            <div className="rate">
                                                <i className="fa fa-star" />
                                                <i className="fa fa-star" />
                                                <i className="fa fa-star" />
                                                <i className="fa fa-star" />
                                                <i className="fa fa-star" />
                                            </div>

                                        </div>
                                    </Link>
                                    <div className="price">
                                        <h4 className="fs-6 fw-bold mt-3" >{'\u09F3'} {productItem.salePrice}</h4>
                                        <button className="mt-2" onClick={() => handleAddToCart(productItem)} title="Add to cart">
                                            <i className="fa fa-plus" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </Slider>
                : (status === STATUSES.LOADING ? <p>Loading...</p> : <p>Unexpected error occured...</p>)
            }
        </>
    )
}

export default FlashCard;