import React from 'react';
import { Link } from "react-router-dom";
import useFetch from "../../utilities/useFetch";

const baseUrl = process.env.REACT_APP_BASE_URL;

const Categories = () => {
    const { data: CategoryData, loading, error } = useFetch(`${baseUrl}/category`);

    const categories = CategoryData ? CategoryData.filter(items => items.parentId === 2) : [];
    const menCategories = CategoryData ? CategoryData.filter(items => items.parentId === 3) : [];

    if (error) console.log(error);

    return (
        <>
            <ul className="dropdown-menu col-6 col-md-12" aria-labelledby="dropdownMenuButton">
                {CategoryData ?
                    CategoryData.filter(i => i.parentId === 2).map((value) => {
                        return (
                            <li className="position-relative d-flex" key={value.componentId}>
                                <div className="dropdown-item d-flex text-center py-0 box align-items-center">
                                    <img className="pt-0 mt-0" src={value.photo} alt="" />
                                    <span>{value.name}</span>
                                    {value.end === false ? <span className="fs-2 arrow">&#8594;</span> : ""}
                                </div>
                                <ul className="dropdown-menu dropdown-submenu position-absolute start-100 bottom-25">
                                    {CategoryData.filter(i => i.parentId === value.componentId).map((item) => {
                                        return (
                                            <li key={item.componentId}>
                                                <Link className="dropdown-item box d-flex align-items-center" to={`/category/${value.name.replace(/ /g, "")}/${item.name.replace(/ /g, "")}/${item.componentId}`}>
                                                    <span>{item.name}</span>
                                                    {item.end === false ? <span className="fs-2 arrow">&#8594;</span> : ""}
                                                </Link>
                                            </li>
                                            /* <li className="d-flex">
                                                <a className="dropdown-item" href="#">Submenu item 3 &raquo; </a>
                                                <ul className="dropdown-menu dropdown-submenu position-absolute start-100 bottom-25">
                                                    <li>
                                                        <a className="dropdown-item" href="#">Multi level 1</a>
                                                    </li>
                                                    <li>
                                                        <a className="dropdown-item" href="#">Multi level 2</a>
                                                    </li>
                                                </ul>
                                            </li> */
                                        )
                                    })
                                    }
                                </ul>


                            </li>
                        );
                    })

                    : (loading ? <p>Loading....</p> : <p>Unexpected error occured...</p>)


                }
            </ul>

        </>
    )
};


const SubCategories = () => {

}

export default Categories;