import React, { Component }  from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Ddata from "./Ddata";
import { Link } from "react-router-dom";
import useFetch from "../../utilities/useFetch";

const baseUrl = process.env.REACT_APP_BASE_URL;


const BigDiscountCard = () => {
  const settings = {
    dots: false,
    infinite: true,
    arrows: false,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 600,
        settings: {
          infinite: true,
          dots: false,
          slidesToShow: 2,
          slidesToScroll: 1,
          // initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          infinite: true,
          dots: false,
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  }

  const { data: discountProductsData, loading, error } = useFetch(`${baseUrl}/product`);

  //   if (loading) return <h1> LOADING...</h1>;

  const discountProducts = discountProductsData?discountProductsData.filter(items => items.componentId > 2):[];

  if (error) console.log(error);

  return (

    <>
      <Slider {...settings} className="mx-lg-5">
        {discountProductsData ?
          discountProducts.map((value) => {
            return (
              <div className="col-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3" key={value.componentId}>
                <Link className="text-decoration-none text-black" to={`/category/bigDiscount/${value.uniqueCode}/products/product/${value.componentId}`}>
                  <div className="product2 mx-2">
                    <div className="">
                      <img className="img-fluid w-75 mx-auto" style={{ height: "200px" }} src={`${baseUrl}/uploads/${value.photo}`} alt="" />
                    </div>
                    <h4 className="fs-6 text-dark" style={{ height: "45px" }}>{value.itemName}</h4>
                    <span className="fw-bold price">{"\u09F3"} {value.salePrice}</span>
                  </div>
                </Link>
              </div>
            )
          })
          : (loading ? <p>Loading....</p> : <p>Unexpected error occured...</p>)
        }
      </Slider>
    </>
  )
}

export default BigDiscountCard;
