import React, { Component }  from 'react';
import BigDiscountCard from "./BigDiscountCard";
import "./BigDiscount.css";
import { Link } from "react-router-dom";


const BigDiscount = () => {
  return (
    <>
      <section className="bigDiscount">
        <div className="container-fluid">
          <div className="bigDiscountHeading d-flex justify-content-between mx-lg-5">
            <div className="bigDiscountHeadingLeft d-flex">
              <img className = "me-2" src="./assets/images/discount/gift.png" alt="" />
              <h2 className="fs-3 fw-bolder mt-1">Big Discounts</h2>
            </div>
            <Link className="text-decoration-none" to = "/category/bigDiscounts/products">  {/*.replace(/ /g, "")*/}
              <div className="bigDiscountHeadingRight text-end d-flex mt-1">
                <span>View All</span>
                <div className="ms-1">
                    <i className="fa-solid fa-caret-right" />
                </div>
              </div>
            </Link>
          </div>
          <BigDiscountCard />
        </div>
      </section>
    </>
  )
}

export default BigDiscount;
