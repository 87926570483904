import React  from 'react';
import "./SingleProduct.css";

const ProductReviews = ({data}) => {
    return (
        <>
            <div className="singleProductCard mt-2">
                <h6>Reviews</h6>
                <div className="d-flex">
                    <div className="stars">
                        <i className="fa fa-star me-1" />
                        <i className="fa fa-star me-1" />
                        <i className="fa fa-star me-1" />
                        <i className="fa fa-star me-1" />
                    </div>
                    <span className="ms-1 fw-bold">4.6</span>
                </div>
                <hr />
                <div className="badges">
                    <span className="badge bg-dark me-1">All (230)</span>
                    <span className="badge bg-dark me-1">
                        <i className="fa fa-image" /> 23
                    </span>
                    <span className="badge bg-dark me-1">
                        <i className="fa fa-comments" /> 23
                    </span>
                    <span className="badge bg-warning">
                        <i className="fa fa-star me-1" />
                        <i className="fa fa-star me-1" />
                        <i className="fa fa-star me-1" />
                        <i className="fa fa-star me-1" />
                        <span className="ms-1">2,123</span>
                    </span>
                </div>
                <hr />
                <div className="comment-section">
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="d-flex">
                            <img src="/assets/images/flash/flash-2.png" alt="" className="rounded-circle profile-image bg-dark" />
                            <div className="d-flex flex-column ms-1 comment-profile">
                                <div className="comment-ratings">
                                    <i className="fa fa-star me-1" />
                                    <i className="fa fa-star me-1" />
                                    <i className="fa fa-star me-1" />
                                    <i className="fa fa-star me-1" />
                                </div>
                                <span className="username">Abdul Karim</span>
                            </div>
                        </div>
                        <div className="date">
                            <span className="text-muted">2 May</span>
                        </div>
                    </div>
                    <hr />
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="d-flex">
                            <img src="/assets/images/flash/flash-2.png" alt="" className="rounded-circle profile-image bg-dark" />
                            <div className="d-flex flex-column ms-1 comment-profile">
                                <div className="comment-ratings">
                                    <i className="fa fa-star me-1" />
                                    <i className="fa fa-star me-1" />
                                    <i className="fa fa-star me-1" />
                                    <i className="fa fa-star me-1" />
                                </div>
                                <span className="username">Robiul Islam</span>
                            </div>
                        </div>
                        <div className="date">
                            <span className="text-muted">12 May</span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ProductReviews;