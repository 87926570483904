import React from 'react';
// import "./SingleProduct.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from 'react-router-dom';

const baseUrl = process.env.REACT_APP_BASE_URL;

const SingleProductImageSlider = ({data}) => {
    const settings = {
        customPaging: function (i) {
            return (
                <div className="text-center">
                    <img src={`/assets/images/flash/flash-${i + 1}.png`} alt="" />
                </div>
            );
        },
        dots: true,
        dotsClass: "slick-dots slick-thumb",
        infinite: true,
        speed: 1,  //500
        slidesToShow: 1,
        slidesToScroll: 1
    };
    return (
        <>
            <div className="singleProductCard">
                <Slider {...settings} className="">
                    <div>
                        <img className="singleImg" src={data.photo ? `${baseUrl}/uploads/${data.photo}` : `/assets/images/flash/flash-1.png`} alt="" />
                    </div>
                    <div>
                        <img className="singleImg " src={data.photo ? data.photo : `/assets/images/flash/flash-2.png`} alt="" />
                    </div>
                    <div>
                        <img className="singleImg " src={data.photo ? data.photo : `/assets/images/flash/flash-3.png`} alt="" />
                    </div>
                    <div>
                        <img className="singleImg " src={data.photo ? data.photo : `/assets/images/flash/flash-4.png`} alt="" />
                    </div>
                </Slider>
            </div>
        </>
    )
}

export default SingleProductImageSlider;