import React from 'react';
// import Navbar from './Navbar';
import Search from './Search';
import './Header.css';

const Header = () => {
    return (
        <>
            <header>
                <div className="fixed-top col-12">
                    <Search />
                    {/* <Navbar /> */}
                </div>
            </header>
        </>

    )
}
// className={`fixed-top ${window.innerWidth < 600 ? `position-relative` : ""}`}
export default Header;